import { Outlet } from "react-router-dom";
import Header from "./Header";
import { Box, Grid, ThemeProvider, Typography, createTheme } from "@mui/material";
import Footer from "./Footer";

const themeHome = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#ffffff',
        contrastText: '#2c2c2c',
      },
    },
    typography: {
      fontFamily: 'SVN-Gilroy-Medium',
      fontSize: 18,
      button: {
        textTransform: 'none'
      }
    }
  });

function Layout() {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <ThemeProvider theme={themeHome}><Header></Header></ThemeProvider>
                    {/* <Header></Header> */}
                </Grid>
                <Grid item xs={12}>
                    <Outlet />
                </Grid>
                <Grid item xs={12}>
                    <Footer></Footer>
                </Grid>
            </Grid>
            
        </>
    );
}

export default Layout;