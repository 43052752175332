import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './component/layout/Layout';
import Home from './component/home/Home';
import Login from './component/login/Login';
import User from './component/user/User';
import NotFound from './component/not-found/NotFound';
import { ThemeProvider } from '@emotion/react';
import { createTheme, unstable_createMuiStrictModeTheme } from '@mui/material';
import LoginLayout from './component/login/LoginLayout';

const theme = createTheme({
  // palette: {
  //   mode: 'light',
  //   primary: {
  //     main: '#ffffff',
  //     contrastText: '#2c2c2c',
  //   },
  // },
  // components: {
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       root: {
  //         fontWeight: 'bold',
  //         fontSize: '14px',
  //         fontFamily: 'SVN-Gilroy-SemiBold',
  //         color: 'black',
  //         "&.Mui-focused": {
  //           color: '#0866ff',
  //         },

  //       },
  //     }
  //   },
  //   MuiTextField: {
  //     defaultProps: {
  //       variant: 'standard',
  //     },
  //   },
  // },
  typography: {
    fontFamily: 'SVN-Gilroy-Medium',
    fontSize: 18,
    button: {
      textTransform: 'none'
    }
  }
});

const themeHome = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
      contrastText: '#2c2c2c',
    },
  },
  typography: {
    fontFamily: 'SVN-Gilroy-Medium',
    fontSize: 18,
    button: {
      textTransform: 'none'
    }
  }
});

function App() {
  return (
    // <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginLayout />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="/" element={<Layout />}>
            
            <Route index element={<ThemeProvider theme={themeHome}><Home /></ThemeProvider>} />

            <Route path="user" element={<User />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>

    // </ThemeProvider>
  );
}

export default App;
