import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import logo from '../../share/images/logo.svg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
    return (
        <Box component="div" sx={{backgroundColor: 'black', borderTop: '1px solid white'}}>

            <Container maxWidth={false} sx={{ maxWidth: "1366px"}} className="Footer">

                <Box component="section" sx={{ pt: 10, pb: 5, color: 'white', fontSize: '18px'}}>
                    <Box component="div" sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Stack spacing={4} width={450}>
                            <img
                                width={165}
                                src={logo}
                                alt="MEXT"
                                style={{ paddingRight: "32px" }}
                            />
                            <Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <LocationOnOutlinedIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">
                                    Tầng 1, Tòa văn phòng T26, KĐT Times City, 458 Minh Khai, Phường Vĩnh Tuy, Quận Hai Bà Trưng, Thành phố Hà Nội
                                </Typography>
                            </Box>
                            <Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <LocalPhoneOutlinedIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">
                                    Hotline: (+84) 123 456 7xx
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack spacing={4} width={230}>
                            <Typography variant="h5" sx={{fontSize: '24px', fontWeight: 'bold', pb: 6}}>
                                Thông tin
                            </Typography>
                            <Typography variant="body1">Tổng quan</Typography>
                            <Typography variant="body1">Mext Store miễn phí</Typography>
                            <Typography variant="body1">Bảng giá</Typography>
                            <Typography variant="body1">Đội ngũ Mext Talents</Typography>
                            <Typography variant="body1">Tuyển dụng</Typography>
                        </Stack>
                        <Stack spacing={4}  width={230}>
                            <Typography variant="h5" sx={{fontSize: '24px', fontWeight: 'bold', pb: 6}}>
                            Hỗ trợ
                            </Typography>
                            <Typography variant="body1">Trung tâm hỗ trợ</Typography>
                            <Typography variant="body1">Điều khoán dịch vụ của khách hàng</Typography>
                            <Typography variant="body1">Chính sách bảo vệ và xử lý dữ liệu</Typography>
                            <Typography variant="body1">Đội ngũ Mext Talents</Typography>
                            <Typography variant="body1">Trở thành đối tác</Typography>
                        </Stack>
                        <Stack spacing={4}  width={230}>
                            <Typography variant="h5" sx={{fontSize: '24px', fontWeight: 'bold', pb: 6}}>
                            Khám phá Mext
                            </Typography>
                            <Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <FacebookOutlinedIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">Facebook</Typography>
                            </Box>
                            <Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <SubscriptionsOutlinedIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">Youtube</Typography>
                            </Box>
                            <Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <FacebookOutlinedIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">TikTok</Typography>
                            </Box><Box component="div" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                <InstagramIcon fontSize="medium" sx={{ pr: 2 }} />
                                <Typography variant="body1">Instagram</Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Divider sx={{marginY: 6, background: 'white'}}></Divider>
                    <Typography variant="caption" sx={{fontSize: '18px'}}>© 2023 Bản quyền thuộc về Mext.vn</Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;