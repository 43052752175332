import { Box } from "@mui/material"

export const Marquee = () => {
    return (
        <Box sx={{ height: '72px', display: 'flex', alignItems: 'center', gap: 4 }}>
            <Box component="span">TIẾT KIỆM 12+ GIỜ / TUẦN</Box>
            <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
            <Box component="span">CHỈ TỪ 11.000đ / NGÀY</Box>
            <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
            <Box component="span" >SỞ HỮU TRỌN ĐỜI</Box>
            <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
            <Box component="span" >MIỄN PHÍ KHỞI TẠO</Box>
            <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
            <Box component="span" >KHÔNG CẦN TRẢ PHÍ THEO NĂM</Box>
        </Box>
    )
}