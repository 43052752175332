import { Box, Button, Divider, FormControl, FormHelperText, Input, InputLabel, Modal, Stack, TextField, Typography } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const ContactModal = ({isShow = false, handleClose}: {isShow: boolean, handleClose: ()=>void}) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: '12px',
        boxShadow: '0 1px 8px 0 rgba(44, 44, 44, 0.08)',
        border: 'solid 2px #fff3ef',
      };
    return (

        <Modal
            open={isShow}
            onClose={handleClose}
        >
            <Box component="form" sx={style}>
                <Stack spacing={2}>
                    <Typography variant="body2" sx={{fontFamily: 'SVN-Gilroy-SemiBold'}}>
                        Để lại thông tin tư vấn
                    </Typography>
                    <Divider sx={{marginY: 3, marginX: -4}} />
                    <FormControl>
                        <TextField id="outlined-basic" label="Họ và tên *" variant="outlined" sx={{
                            // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     borderColor: "green"
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     borderColor: "red"
                            //   },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#0866ff"
                            },
                            //   "& .MuiOutlinedInput-input": {
                            //     color: "green"
                            //   },
                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "red"
                            //   },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#0866ff"
                            },
                            //   "& .MuiInputLabel-outlined": {
                            //     color: "green"
                            //   },
                            //   "&:hover .MuiInputLabel-outlined": {
                            //     color: "red"
                            //   },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#0866ff"
                            }

                        }} focused/>
                    </FormControl>
                    <FormControl>
                        <TextField id="outlined-basic" label="Số điện thoại *" variant="outlined" sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0866ff"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#0866ff"
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#0866ff"
                            }
                        }}/>
                    </FormControl>
                    <FormControl>
                        <TextField id="outlined-basic" label="Email *" variant="outlined" sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0866ff"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#0866ff"
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#0866ff"
                            }
                        }}/>
                    </FormControl>
                    <FormControl>
                        <TextField id="outlined-basic" label="Dịch vụ quan tâm *" variant="outlined" sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0866ff"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#0866ff"
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#0866ff"
                            }
                        }}/>
                    </FormControl>
                    <FormControl>
                        <TextField id="outlined-basic" label="Bạn cần trợ giúp điều gì?" variant="outlined" multiline rows={4} sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#0866ff"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#0866ff"
                            },
                            "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#0866ff"
                            }
                        }}/>
                    </FormControl>
                    <Box component="div" sx={{pt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Button
                            sx={{ height: '46px', padding: '12px 16px', backgroundColor: '#0866ff', '&:hover': {backgroundColor: '#1f52a5'} }}
                        >

                            Gửi ngay
                            <ArrowForwardIcon fontSize="small" sx={{pl: 1}}/>
                        </Button>
                        <Box component="div" sx={{textAlign: 'right'}}>
                            <Typography variant="body1">Khách hàng doanh nghiệp?</Typography>
                            <Button
                                            sx={{ color: "#0866ff", alignSelf: 'end', fontFamily: 'SVN-Gilroy-SemiBold' }}
                                        >
                                            Gọi cho Sales
                                            <LocalPhoneIcon fontSize="small" sx={{pl: 1}}></LocalPhoneIcon>
                                        </Button>
                        </Box>

                    </Box>
                </Stack>

            </Box>
        </Modal>
    )
}