import { AppBar, Avatar, BottomNavigation, BottomNavigationAction, Box, Button, Container, IconButton, Link, Menu, MenuItem, Paper, SvgIcon, Toolbar, Tooltip, Typography, makeStyles } from "@mui/material";
import { popoverClasses } from "@mui/material/Popover"
import React, { useEffect, useState } from "react";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { Image } from "@mui/icons-material";
import logo from '../../share/images/logo.svg';
import lineDown from '../../share/images/line-down.svg';
import { SubMenuLayout } from "./SubMenuLayout";
import { SubMenuItemChildType, SubMenuItemType } from "./SubMenuItem";

// function refreshMessages(): MessageExample[] {
//     const getRandomInt = (max: number) => Math.floor(Math.random() * Math.floor(max));

//     return Array.from(new Array(50)).map(
//       () => messageExamples[getRandomInt(messageExamples.length)],
//     );
//   }

const pages: { label: string; child?: SubMenuItemType[] }[] = [
    {
        label: 'Đánh giá',
    }, {
        label: 'Sản phẩm',
        child: [
            {
                title: 'Mext Talents phát triển (Miễn phí)',
                items: [
                    {
                        lable: 'Extension cho Chrome',
                        url: ''
                    }, {
                        lable: 'App tự động hoá - Auto Click',
                        url: ''
                    }, {
                        lable: 'Ứng dụng cho Windows',
                        url: ''
                    }, {
                        lable: 'Template Excel / Google Sheet',
                        url: ''
                    }, {
                        lable: 'Truy cập Mext Store',
                        url: ''
                    }
                ]
            }, {
                title: 'Tính năng vượt trội',
                items: [
                    {
                        lable: 'Tự động hoá thao tác lặp lại',
                        url: ''
                    }, {
                        lable: '1-click / Auto Click cho MMO',
                        url: ''
                    }, {
                        lable: 'Hỗ trợ công việc văn phòng',
                        url: ''
                    }, {
                        lable: 'Quản lý thời gian - tài chính cá nhân',
                        url: ''
                    }, {
                        lable: 'Larksuite Templates',
                        url: ''
                    }
                ]
            }, {
                title: 'Nền tảng',
                items: [
                    {
                        lable: 'Trình duyệt / Extension',
                        url: ''
                    }, {
                        lable: 'Windows App / Script',
                        url: ''
                    }, {
                        lable: 'Thiết bị di động',
                        url: ''
                    }, {
                        lable: 'Kết nối Zalo Mini App / Telegram',
                        url: ''
                    }, {
                        lable: 'Giải pháp API / Webhook',
                        url: ''
                    }
                ]
            }
        ]
    }, {
        label: 'Thông tin',
        child: [
            {
                title: 'Tìm hiểu',
                items: [
                    {
                        lable: 'Quyền lợi khách hàng',
                        url: ''
                    }, {
                        lable: 'Chính sách khách hàng',
                        url: ''
                    }, {
                        lable: 'Trung tâm hỗ trợ',
                        url: ''
                    }
                ]
            }, {
                title: 'Trợ giúp',
                items: [
                    {
                        lable: 'Hướng dẫn',
                        url: ''
                    }, {
                        lable: 'Tích hợp',
                        url: ''
                    }, {
                        lable: 'Nhà phát triển',
                        url: ''
                    }, {
                        lable: 'Hỏi đáp',
                        url: ''
                    }
                ]
            }, {
                title: 'Kết nối',
                items: [
                    {
                        lable: 'Tin tức',
                        url: ''
                    }, {
                        lable: 'Blog kiến thức',
                        url: ''
                    }, {
                        lable: 'Cộng đồng Mext Valley',
                        url: ''
                    }, {
                        lable: 'Đối tác tin cậy',
                        url: ''
                    }, {
                        lable: 'Dịch vụ cho Doanh nghiệp',
                        url: ''
                    }
                ]
            }
        ]
    }, {
        label: 'Trở thành đối tác',
    }, {
        label: 'Bảng giá',
    }, {
        label: 'Liên hệ',
    }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElChild, setAnchorElChild] = React.useState<null | HTMLElement>(null);
    const [childSelected, setChildSelected] = useState<SubMenuItemType[]>([])
    const [isShowSubMenu, setIsShowSubMenu] = useState<boolean>(false);
    useEffect(() => {
        setChildSelected(pages[1].child ?? [])
    }, [setChildSelected])

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleOpenChildMenu = (event: React.MouseEvent<HTMLElement>, child: any) => {
        setAnchorElChild(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseChildMenu = () => {
        setAnchorElChild(null);
    };

    const onClickSubMenu = (event: any, child: SubMenuItemType[]) => {
        setIsShowSubMenu(true);
        if (childSelected !== child) {
            setChildSelected(child ?? []);
        }
    }

    return (
        <AppBar position="static">
            <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                <Toolbar disableGutters sx={{ height: "88px" }}>
                    <Link href="/">
                        <img
                            width={100}
                            src={logo}
                            alt="MEXT"
                            style={{ paddingRight: "32px" }}
                        />
                    </Link>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Box key={page.label}>
                                <Button
                                    aria-haspopup="true"
                                    key={page.label}
                                    onMouseOver={(event) => page.child ? onClickSubMenu(event, page.child ?? []) : setIsShowSubMenu(false)}
                                    sx={{
                                        my: 2,
                                        mr: { xs: 2, lg: 4 },
                                        color: "#2c2c2c",
                                        display: "block",
                                        fontWeight: "bold",
                                        fontSize: 16,
                                    }}
                                >
                                    {page.label}&nbsp;
                                    {page.child ? <img width={10} src={lineDown} alt="" /> : ""}
                                </Button>
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "32px",
                        }}
                    >
                        <Tooltip title="Open settings">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0, display: "none" }}
                            >
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                        <Button
                            sx={{
                                backgroundColor: "#0866ff",
                                '&:hover': {backgroundColor: '#1f52a5'},
                                fontWeight: "bold",
                                padding: "12px 16px",
                            }}
                            href="/login"
                        >
                            Đăng nhập
                        </Button>
                        {/* <Button
                            sx={{ height: '46px', padding: '12px 16px', backgroundColor: '#ff6900', fontWeight: 'bold' }}
                        >
                            Dùng thử miễn phí
                        </Button> */}
                    </Box>
                </Toolbar>
            </Container>
            <Box component="div" onMouseLeave={()=>setIsShowSubMenu(false)}
                sx={{
                    display: isShowSubMenu ? "" : "none",
                    position: "absolute",
                    zIndex: 2,
                    top: "88px",
                    backgroundColor: "white",
                    width: "100%",
                    borderTop: "1px solid #bbbcbc",
                    borderBottom: "1px solid #bbbcbc",
                }}
            >
                <Container maxWidth={false} sx={{ maxWidth: "1366px", paddingY: 6 }}>
                    {childSelected.length && (
                        <SubMenuLayout child={childSelected}></SubMenuLayout>
                    )}
                </Container>
            </Box>
            <Box component="div" sx={{position: 'fixed', width: '100vw', height: '100vh', top: 100, left: 0, backgroundColor:'gray', display: isShowSubMenu ? "" : "none", zIndex: 1, opacity: 0.7}}></Box>
        </AppBar>
    );
}

export default Header;