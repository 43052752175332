import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import logo from '../../share/images/logo.svg';
import { Link, Outlet } from "react-router-dom";

function LoginLayout() {
    return (
        <Box component="form" className="Login" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center'}}>
            <Box component="img" width={100} src={logo} alt="MEXT" sx={{mb: 2}}/>
            <Outlet />
        </Box>
    );
}

export default LoginLayout;