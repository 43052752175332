import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { SubMenuItem, SubMenuItemType } from "./SubMenuItem";

export function SubMenuLayout({ child }: { child: SubMenuItemType[] }) {
    console.log("child", child);

    return (
        <Box sx={{ display: 'flex', gap: 8 }}>
            {child.map((item) => (
                <SubMenuItem {...item} key={item.title}></SubMenuItem>
            ))}
        </Box>
    );
}