import { Accordion, AccordionSummary, AccordionDetails, Avatar, Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import './../../common/styles/home.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image4 from '../../share/images/image-4.jpg';
import image3 from '../../share/images/image-3.jpg';
import image5 from '../../share/images/image-5.jpg';
import image6 from '../../share/images/image-6.jpg';
import image1 from '../../share/images/image-1.jpg';
import image2 from '../../share/images/image-2.jpg';
import ic1 from '../../share/images/ic1.svg';
import banner from '../../share/images/banner.svg';
import filledStar from '../../share/images/Filled_Star.svg';
import phone from '../../share/images/icon_phone.svg';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Marquee } from "./Marquee";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { CarouselBtn } from "./NextBackBtnCarousel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { ContactModal } from "../contact-modal/ContactModal";
import { useState } from "react";
import AddCommentIcon from '@mui/icons-material/AddComment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import StopCircleIcon from '@mui/icons-material/StopCircle';

// const useStyles = makeStyles({
//     button: {
//       backgroundColor: '#0866ff',
//       color: '#fff',
//       '&:hover': {
//         backgroundColor: '#1f52a5',
//         color: '#3c52b2',
//     },
//   }})

function Home() {
    // const classes = useStyles();
    const [isShowModal, setIsShowModal] = useState<boolean>(false)

    return (
        // <Container maxWidth={false} sx={{ maxWidth: "1366px" }} className="Home">
        <Box component="div" className="Home">
            <Box component="section">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{ display: 'flex', paddingY: '100px' }}>
                        <Box>
                            <Stack>
                                <Box component="div" sx={{ display: 'flex', gap: '4px' }}>
                                    <img src={filledStar} alt="" />
                                    <img src={filledStar} alt="" />
                                    <img src={filledStar} alt="" />
                                    <img src={filledStar} alt="" />
                                    <img src={filledStar} alt="" />
                                </Box>
                                <Typography variant="caption" sx={{ pt: 1, fontSize: '18px', color: "#555555" }}>
                                    300+ đánh giá tích cực từ các Freelancer hàng đầu
                                </Typography>
                                <Typography variant="h2" sx={{ pt: 3, fontSize: '48px', fontWeight: 'bold', fontFamily: 'SVN-Gilroy-XBold' }}>
                                    Giải pháp tự động hoá <br></br>công việc của bạn
                                </Typography>
                                <Typography variant="h5" sx={{ pt: 8, fontSize: '24px', fontWeight: '500' }}>
                                    Hãy để công nghệ làm thuê cho bạn<br></br>
                                    Chỉ từ 11.000đ / ngày. Support mọi nơi, mọi lúc.
                                </Typography>
                                <Box component="div" sx={{ pt: 4 }}>
                                    <Button
                                        sx={{ height: '46px', padding: '12px 16px', backgroundColor: '#0866ff', '&:hover': {backgroundColor: '#1f52a5'}, mr: 4 }}
                                    >
                                        Dùng thử miễn phí
                                    </Button>
                                    <Button
                                        sx={{ height: '46px', padding: '12px 16px', color: "#2c2c2c", border: "solid 1px #2c2c2c" }}
                                    >

                                        Đăng ký tư vấn
                                        <ArrowForwardIcon fontSize="small" />
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                        <Box>
                            <img width={528} src={banner} alt="" style={{ maxWidth: "100vw" }} />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box component="section">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box>
                        <Box component="div" className="marquee-section">
                            <Box component="div" className="marquee-div">
                                <Box className="marquee" sx={{ height: '72px', display: 'flex', alignItems: 'center', gap: 4 }}>
                                    <Marquee></Marquee>


                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>
                                    <Marquee></Marquee>
                                    <Box component="span" sx={{ width: '1.5px', height: '24px', flexGrow: 0, backgroundColor: '#ccc' }}></Box>

                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Container>
            </Box>
            <Box component="section" className="section3">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{ display: 'flex', pt: '100px', pb: 2 }}>
                        <Stack sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: '48px' }}>
                                Hiệu suất hơn. Tự do hơn
                            </Typography>
                            <Typography variant="body1" sx={{ pt: 3, fontSize: 24 }}>
                                Chúng tôi cung cấp dịch vụ tối ưu hoá mọi thao tác phục vụ công việc của bạn.
                            </Typography>
                            <Box sx={{ pt: 6, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Box sx={{ width: 600 }}>
                                    <img width={600} src={banner} alt="" style={{ maxWidth: "100vw" }} />
                                </Box>
                                <Box component="div" sx={{ width: 600 }}>
                                    <Stack spacing={2}>
                                        <Box component="div" sx={{ padding: 3, backgroundColor: "#001e7a", color: 'white', borderRadius: 1 }}>
                                            <Box component="div" sx={{ display: 'flex', alignItems: "center", gap: '16px', fontSize: '24px', fontWeight: 'bold' }}>
                                                <ArrowForwardIcon fontSize="small" />
                                                Excel VBA / Google Sheet Script
                                            </Box>
                                            <Box sx={{ pt: 3 }}>
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 150.
                                            </Box>
                                        </Box>
                                        <Box component="div" className="box1">
                                            <Box component="div" className="box2" sx={{fontSize: '24px', fontWeight: 'bold'}}>
                                                <ArrowForwardIcon fontSize="small" />
                                                Tự động hoá bằng Windows App
                                            </Box>
                                        </Box>
                                        <Box component="div" className="box1">
                                            <Box component="div" className="box2" sx={{fontSize: '24px', fontWeight: 'bold'}}>
                                                <ArrowForwardIcon fontSize="small" />
                                                Ứng dụng Extension trình duyệt
                                            </Box>
                                        </Box>
                                        <Box component="div" className="box1">
                                            <Box component="div" className="box2" sx={{fontSize: '24px', fontWeight: 'bold'}}>
                                                <ArrowForwardIcon fontSize="small" />
                                                Viết API / Webhook kết nối
                                            </Box>
                                        </Box>
                                        <Box component="div" className="box1">
                                            <Box component="div" className="box2" sx={{fontSize: '24px', fontWeight: 'bold'}}>
                                                <ArrowForwardIcon fontSize="small" />
                                                Tối ưu mọi thao tác
                                            </Box>
                                        </Box>
                                        <Button
                                            sx={{ color: "#0866ff", alignSelf: 'end' }}
                                        >
                                            Dùng thử miễn phí ngay
                                            <ArrowForwardIcon fontSize="small" sx={{ pl: 1 }} />
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Box component="section" className="section4">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{ display: 'flex', paddingY: '100px', width: '100%' }}>
                        <Stack sx={{ width: '100%' }}>
                            <Typography variant="caption" sx={{ color: "#0866ff", fontSize: '24px' }}>
                                Chỉ với 5 bước, Mext sẽ giúp bạn
                            </Typography>
                            <Typography variant="h2" sx={{ pt: 3, fontSize: '48px', fontWeight: 'bold' }}>
                                Sở hữu hiệu suất làm việc vượt trội.
                            </Typography>
                            <Box component="div" sx={{ pt: 6, display: 'flex', justifyContent: 'space-between' }}>
                                <Stack sx={{ width: '278px' }}>
                                    <img width={192} height={192} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                    <Typography variant="body1" sx={{ pt: 4, fontWeight: 'bold', fontSize: '25px' }}>Tư vấn giải pháp</Typography>
                                    <Typography variant="body2" sx={{ pt: 3 }}>Đội ngũ Mext Talents sẽ tư vấn giải pháp công nghệ nhằm giúp bạn cải thiện hiệu suất làm việc.</Typography>
                                </Stack>
                                <Stack sx={{ width: '278px' }}>
                                    <img width={192} height={192} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                    <Typography variant="body1" sx={{ pt: 4, fontWeight: 'bold', fontSize: '25px' }}>Phát triển dự án</Typography>
                                    <Typography variant="body2" sx={{ pt: 3 }}>Sau khi chốt giải pháp công nghệ, Mext Talents sẽ cùng làm việc với bạn bằng sự nỗ lực và cam kết cao nhất.</Typography>
                                </Stack>
                                <Stack sx={{ width: '278px' }}>
                                    <img width={192} height={192} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                    <Typography variant="body1" sx={{ pt: 4, fontWeight: 'bold', fontSize: '25px' }}>Dùng thử - Sửa lỗi</Typography>
                                    <Typography variant="body2" sx={{ pt: 3 }}>Bạn trải nghiệm ứng dụng Mext trọn vẹn và miễn phí trong 14 ngày đầu tiên, và cung cấp lỗi cần sửa chữa nếu có.</Typography>
                                </Stack>
                                <Stack sx={{ width: '278px' }}>
                                    <img width={192} height={192} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                    <Typography variant="body1" sx={{ pt: 4, fontWeight: 'bold', fontSize: '25px' }}>Sở hữu trọn đời</Typography>
                                    <Typography variant="body2" sx={{ pt: 3 }}>Bạn đã có toàn quyền sở hữu. Gia hạn bảo trì hàng tháng để Mext hỗ trợ bạn 24/7. Huỷ gia hạn bất cứ khi nào.</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Box component="section" className="section5" sx={{backgroundColor: '#f6f7f8'}}>
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{ paddingY: '100px', display: 'flex', gap: '48px' }}>
                        <Box component="div">
                            <Stack spacing={4}>
                                <img width={600} height={271} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                        <img width={64} height={64} src={banner} alt="" style={{ borderRadius: '8px' }} />
                                        <Typography variant="body1">Freelancer</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                        <img width={64} height={64} src={banner} alt="" style={{ borderRadius: '8px' }} />
                                        <Typography variant="body1">Freelancer</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                        <img width={64} height={64} src={banner} alt="" style={{ borderRadius: '8px' }} />
                                        <Typography variant="body1">Freelancer</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                        <img width={64} height={64} src={banner} alt="" style={{ borderRadius: '8px' }} />
                                        <Typography variant="body1">Freelancer</Typography>
                                    </Box>
                                    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                        <img width={64} height={64} src={banner} alt="" style={{ borderRadius: '8px' }} />
                                        <Typography variant="body1">Freelancer</Typography>
                                    </Box>
                                </Box>
                            </Stack>
                        </Box>
                        <Box component="div">
                            <Stack spacing={6}>
                                <Typography variant="h2" sx={{ fontSize: '48px', fontWeight: 'bold' }}>Dịch vụ của Mext<br></br>dành cho Ai?</Typography>
                                <Box component="span" sx={{ fontSize: '24px', pr: 0.5 }}>
                                    Bất kể công việc và lĩnh vực mà bạn đang làm, Mext có thể cung cấp dịch vụ công nghệ giúp bạn<Box component="span" sx={{ color: '#0866ff' }}>&nbsp;giảm tới 60%&nbsp;</Box>khối lượng công việc mỗi ngày.<br></br><br></br><Box component="span" sx={{ color: '#0866ff' }}>100+ Khách hàng&nbsp;</Box>đã tiết kiệm được ít nhất 3h làm việc mỗi ngày, và con số vẫn tiếp tục tăng lên.
                                </Box>
                                <Button
                                    sx={{ color: "white", alignSelf: 'start', backgroundColor: '#0866ff', '&:hover': {backgroundColor: '#1f52a5'}, borderRadius: '8px', padding: '12px 16px', fontSize: '18px' }}
                                >
                                    Tìm hiểu Case Study
                                    <ArrowForwardIcon fontSize="small" sx={{ pl: 1 }} />
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box component="section" className="section6">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{ paddingY: '100px' }}>
                        <Stack>
                            <Typography variant="h2" sx={{ fontSize: '48px', fontWeight: 'bold' }}>Khách hàng ấn tượng gì về Mext</Typography>
                            <Typography variant="body1" sx={{ fontSize: '24px', pt: 3 }}>Hơn 95% khách hàng vẫn tiếp tục sử dụng dịch vụ của Mext để tiết kiệm thời gian làm việc mỗi ngày.</Typography>
                            <Box component="div" sx={{width: '100%', pt: 6}}>

                                <Carousel
                                    renderButtonGroupOutside
                                    customButtonGroup={<CarouselBtn />}
                                    arrows={false}
                                    responsive = {{
                                        desktop: {
                                            breakpoint: {
                                            max: 3000,
                                            min: 1024
                                            },
                                            items: 3,
                                            partialVisibilityGutter: 40
                                        },
                                        mobile: {
                                            breakpoint: {
                                            max: 464,
                                            min: 0
                                            },
                                            items: 1,
                                            partialVisibilityGutter: 30
                                        },
                                        tablet: {
                                            breakpoint: {
                                            max: 1024,
                                            min: 464
                                            },
                                            items: 2,
                                            partialVisibilityGutter: 30
                                        }
                                        }
                                    }
                                >
                                    <Card className="card-tag">
                                        <CardContent>
                                            <Stack spacing={8} className="card-tag-stack">
                                                <Box component="div" className="card-tag-stack-box">
                                                    <Avatar sx={{ width: '99px', height: '99px' }} className="card-tag-stack-box-avatar"></Avatar>
                                                    <Stack>
                                                        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '24px' }}>Nguyễn Khoa</Typography>
                                                        <Typography variant="caption" sx={{ fontSize: '18px' }}>CMO, ZNET Tech</Typography>
                                                        <Box component="div" sx={{ display: 'flex', gap: '4px' }}>
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="body1">
                                                    “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...”
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <Card className="card-tag">
                                        <CardContent>
                                            <Stack spacing={8} className="card-tag-stack">
                                                <Box component="div" className="card-tag-stack-box">
                                                    <Avatar sx={{ width: '99px', height: '99px' }} className="card-tag-stack-box-avatar"></Avatar>
                                                    <Stack>
                                                        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '24px' }}>Nguyễn Khoa</Typography>
                                                        <Typography variant="caption" sx={{ fontSize: '18px' }}>CMO, ZNET Tech</Typography>
                                                        <Box component="div" sx={{ display: 'flex', gap: '4px' }}>
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="body1">
                                                    “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...”
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <Card className="card-tag">
                                        <CardContent>
                                            <Stack spacing={8} className="card-tag-stack">
                                                <Box component="div" className="card-tag-stack-box">
                                                    <Avatar sx={{ width: '99px', height: '99px' }} className="card-tag-stack-box-avatar"></Avatar>
                                                    <Stack>
                                                        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '24px' }}>Nguyễn Khoa</Typography>
                                                        <Typography variant="caption" sx={{ fontSize: '18px' }}>CMO, ZNET Tech</Typography>
                                                        <Box component="div" sx={{ display: 'flex', gap: '4px' }}>
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="body1">
                                                    “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...”
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <Card className="card-tag">
                                        <CardContent>
                                            <Stack spacing={8} className="card-tag-stack">
                                                <Box component="div" className="card-tag-stack-box">
                                                    <Avatar sx={{ width: '99px', height: '99px' }} className="card-tag-stack-box-avatar"></Avatar>
                                                    <Stack>
                                                        <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: '24px' }}>Nguyễn Khoa</Typography>
                                                        <Typography variant="caption" sx={{ fontSize: '18px' }}>CMO, ZNET Tech</Typography>
                                                        <Box component="div" sx={{ display: 'flex', gap: '4px' }}>
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                            <img src={filledStar} alt="" />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Typography variant="body1">
                                                    “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...”
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    
                                </Carousel>
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Box component="section">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box className="section7">
                        <Grid container>
                            <Grid xs={6}>
                                <Stack spacing={2} sx={{p: 8}}>
                                    <Box component="h2" sx={{fontSize: '48px', pb: 2}}>
                                        Tiết kiệm thời gian.<br></br>Tận hưởng cuộc sống.
                                    </Box>
                                    <Box component="div" sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                        <Avatar sx={{width: '36px', height: '36px'}}></Avatar>
                                        <Typography variant="body1" sx={{fontSize: '24px'}}>Lorem ipsum</Typography>
                                    </Box>
                                    <Box component="div" sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                        <Avatar sx={{width: '36px', height: '36px'}}></Avatar>
                                        <Typography variant="body1" sx={{fontSize: '24px'}}>Lorem ipsum</Typography>
                                    </Box>
                                    <Box component="div" sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                        <Avatar sx={{width: '36px', height: '36px'}}></Avatar>
                                        <Typography variant="body1" sx={{fontSize: '24px'}}>Lorem ipsum</Typography>
                                    </Box>
                                    <Button
                                            sx={{ color: "#0866ff", alignSelf: 'start', fontSize: '24px', pl: 0 }}
                                        >
                                            Liên hệ ngay
                                            <ArrowForwardIcon fontSize="small" sx={{ pl: 1 }} />
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid xs={6} sx={{backgroundColor: '#001e7a'}}>
                                <Stack spacing={3} sx={{p: 8, color: 'white'}}>
                                    <Button
                                            sx={{ alignSelf: 'start', fontSize: '24px', border: 'solid 1px #fff' }}
                                        >
                                            Giá ngày hôm nay
                                            <KeyboardArrowDownIcon fontSize="large" sx={{ pl: 1 }} />
                                    </Button>
                                    <Box component="h2" sx={{fontSize: '90px', pt: 3}}>
                                        11.000đ
                                    </Box>
                                    <Box component="div" sx={{display: 'flex', alignItems: 'center', gap: 2, fontSize: '24px',}}>
                                        mỗi ngày
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box component="section">
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{paddingY: 12}}>
                        <Stack>
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', pb: 6 }}>
                                <Stack spacing={3}>
                                    <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Câu hỏi thường gặp - FAQs</Typography>
                                    <Typography variant="body1" sx={{fontSize: '24px'}}>
                                        Các thắc mắc thường gặp khi bắt đầu sử dụng.<br></br>
                                        Nếu bạn cần hỗ trợ thêm, vui lòng liên hệ Đội ngũ Mext Talents.
                                    </Typography>
                                </Stack>
                                <Box component="div">
                                    <img width={336} height={144} src={banner} alt="" style={{ borderRadius: '16px' }} />
                                </Box>
                            </Box>
                            {/* <Divider sx={{paddingY: 6}} /> */}
                            <Accordion sx={{paddingY: '48px'}}>
                                <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Mext có phải là một phần mềm?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{paddingY: '48px'}}>
                                <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Giá dịch vụ là bao nhiêu?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Sau thời gian dùng thử, khách hàng sẽ gia hạn gói dịch vụ phù hợp (Starter, Pro, Team, Company) tuỳ theo số lượng tài khoản ngân hàng và số lượng giao dịch vào ra để sử dụng, chi tiết xem tại Báo giá
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{paddingY: '48px'}}>
                                <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Lorem ipsum texting example?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{paddingY: '48px'}}>
                                <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Lorem ipsum texting example?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{paddingY: '48px'}}>
                                <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography variant="h2" sx={{fontSize: '48px', fontWeight: 'bold'}}>Lorem ipsum texting example?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Box>
                </Container>
            </Box>
            <Box component="section" sx={{ backgroundColor: '#001e7a' }}>
                <Container maxWidth={false} sx={{ maxWidth: "1366px" }}>
                    <Box sx={{paddingY: 15, color: 'white', display: 'flex', justifyContent: 'center'}}>
                        <Box component="div" sx={{display: 'flex', gap: '48px', alignItems: 'center'}}>
                            <Stack spacing={2}>
                                <Typography variant="h1" sx={{fontSize: '48px', fontWeight: 'bold'}}>Trải nghiệm Mext ngay</Typography>
                                <Typography variant="body1" sx={{fontSize: '20px'}}>
                                    Miễn phí dùng thử 90 ngày không giới hạn tính năng các ứng dụng Mext sẵn có.
                                </Typography>
                            </Stack>
                            <Button
                                    sx={{ color: "white", backgroundColor: '#0866ff', '&:hover': {backgroundColor: '#1f52a5'}, borderRadius: '8px', padding: '12px 16px', fontSize: '18px' }}
                                    onClick={()=>setIsShowModal(true)}
                                >
                                    Liên hệ với chúng tôi
                                    <ArrowForwardIcon fontSize="small" sx={{ pl: 1 }} />
                                </Button>
                        </Box>
                    </Box>
                </Container>

            </Box>
            <ContactModal isShow={isShowModal} handleClose={()=>setIsShowModal(false)}></ContactModal>
            <Box component="div" sx={{position: 'fixed', bottom: '50px', left: '25px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <Box component="a" sx={{backgroundColor: '#fff', borderRadius: '16px', p: 0.5, boxShadow: '0 4px 24px -4px rgba(0, 0, 0, 0.25)', display: 'flex', flexDirection: 'column', alignItems: 'center'}} href="https://zalo.me/nentang1office">
                    <StopCircleIcon fontSize="large"></StopCircleIcon>
                    {/* <Typography variant="caption" sx={{fontSize: '14px', fontWeight: 600}}>Zalo</Typography> */}
                </Box>
                <Box component="a" sx={{backgroundColor: '#fff', borderRadius: '16px', p: 0.5, boxShadow: '0 4px 24px -4px rgba(0, 0, 0, 0.25)', display: 'flex', flexDirection: 'column', alignItems: 'center'}} href="tel:19003313">
                    <LocalPhoneIcon fontSize="medium"></LocalPhoneIcon>
                    <Typography variant="caption" sx={{fontSize: '14px', fontWeight: 600}}>Gọi</Typography>
                </Box>
                <Box component="button" sx={{backgroundColor: '#fff', borderRadius: '16px', p: 0.5, boxShadow: '0 4px 24px -4px rgba(0, 0, 0, 0.25)', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                    onClick={()=>setIsShowModal(true)}
                >
                    <AddCommentIcon fontSize="medium"></AddCommentIcon>
                    <Typography variant="caption" sx={{fontSize: '14px', fontWeight: 600}}>Liên hệ</Typography>
                </Box>
            </Box>
        </Box>
        // </Container>
    );
}

export default Home;