import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import logo from '../../share/images/logo.svg';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input'

function Login() {
    const [isVerifyPhonenumber, setIsVerifyPhonenumber] = useState<boolean>(false);
    const [countTime, setCountTime] = useState<number>(60);
    const [otp, setOtp] = useState('')

    const handleChange = (newValue: string) => {
        setOtp(newValue)
    }
    useEffect(() => { 
  
        //Implementing the setInterval method
        if (countTime > 0) {
            const interval = setInterval(() => { 
                setCountTime(countTime - 1); 
            }, 1000); 
            return () => clearInterval(interval); 
        }
    }, [countTime]); 
    return (
        isVerifyPhonenumber ? 
        <Box component="div" sx={{width: 440, maxWidth: 440, padding: '40px 32px', borderRadius: 2, border: 'solid 0.5px #ccc', display: 'flex', flexDirection: 'column', gap: 2}}>
            <Typography variant="body1" sx={{fontWeight: 'bold', color: '#000000', textAlign: 'center'}}>
                Xác thực số điện thoại
            </Typography>
            <Typography variant="caption">Điền mã OTP vừa được gửi đến số điện thoại {'0988338888'}</Typography>
            <Box>
                <MuiOtpInput value={otp} onChange={handleChange} length={6} />
            </Box>
            <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Link to={"/"} >Đổi số điện thoại</Link>
                {countTime === 0 ? 
                <Button
                    sx={{ padding: '5px', fontSize: '14px', color: 'white', backgroundColor: '#0866ff', '&:hover': {backgroundColor: '#1f52a5'} }}
                    onClick={()=>setCountTime(60)}
                >
                    Gửi lại
                </Button>
                : <Typography variant="caption">Gửi lại OTP sau ({countTime})</Typography>}
                
            </Box>
            <Button variant="outlined" sx={{color: '#555555', backgroundColor: '#eeeeee', borderRadius: '8px', '&:hover': {backgroundColor: '#cbc9c9'}}}>Xác nhận</Button>
            <Divider>Hoặc</Divider>
            <Button variant="outlined" sx={{color: '#555555', borderRadius: '8px', border: '1px solid black', '&:hover': {border: '1px solid black'}}}>Google</Button>
            <Box>Bằng việc chọn Tiếp tục, tôi xác nhận đồng ý với&nbsp;<Link to={"/"} >Chính sách bảo vệ và xử lý dữ liệu</Link>&nbsp;của Mext.</Box>
        </Box>
        :
        <Box component="div" sx={{width: 440, maxWidth: 440, padding: '40px 32px', borderRadius: 2, border: 'solid 0.5px #ccc', display: 'flex', flexDirection: 'column', gap: 2}}>
            <Typography variant="body1" sx={{fontWeight: 'bold', color: '#000000', textAlign: 'center'}}>
                Chào mừng Bạn quay lại với Mext!
            </Typography>
            <TextField
                required
                id="outlined-basic"
                label="Số điện thoại hoặc Email"
                variant="outlined"
            />
            <TextField
                required
                id="outlined-basic"
                label="Mật khẩu"
                variant="outlined"
                type="password"
            />
            <Button variant="outlined" sx={{color: '#555555', backgroundColor: '#eeeeee', borderRadius: '8px', '&:hover': {backgroundColor: '#cbc9c9'}}}
                onClick={()=>setIsVerifyPhonenumber(true)}
            >Đăng nhập</Button>
            <Divider>Hoặc</Divider>
            <Button variant="outlined" sx={{color: '#555555', borderRadius: '8px', border: '1px solid black', '&:hover': {border: '1px solid black'}}}>Google</Button>
            <Link to={"/"} >Quên mật khẩu?</Link>
            <Box>Bạn chưa có tài khoản Mext? <Link to={"/"} >&nbsp;Đăng ký tài khoản</Link></Box>
        </Box>
    );
}

export default Login;