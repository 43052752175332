import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export type SubMenuItemChildType = {
    lable: string;
    url: string;
    color?: string;
}

export type SubMenuItemType = {
    title: string;
    items: SubMenuItemChildType[]
}


export function SubMenuItem(props: SubMenuItemType) {
    return (
        <Stack sx={{ width: 320 }} spacing={4} >
            <Box component="div" sx={{ fontSize: 18, color: '#97999b', borderBottom: '1px solid rgba(16, 16, 16, 0.5)', paddingBottom: 1 }}>{props.title}</Box>
            {props.items.map((item) => (
                <Link to={item.url} key={item.lable}>
                    <Box component="div" sx={{ color: item.color, fontSize: 18, fontWeight: 'bold' }}>{item.lable}</Box>
                </Link>
            ))}
        </Stack>
    );
}