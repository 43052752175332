import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Carousel, { ButtonGroupProps } from 'react-multi-carousel'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const CarouselBtn = ({ next, previous }: ButtonGroupProps ) => {
  return (
    <Box component="div" sx={{ display: 'flex', gap: 4, justifyContent: 'end', pt: 6 }}>
            <Button sx={{ color: "blue", borderRadius: '8px', padding: '12px', width: 56, height: 56, border: 'solid 2.5px #0866ff' }}
            onClick={() => previous?.()}>
                <ArrowBackIcon fontSize="large" />
            </Button>
            <Button sx={{ color: "blue", borderRadius: '8px', padding: '12px', width: 56, height: 56, border: 'solid 2.5px #0866ff' }}
            onClick={() => next?.()}>
                <ArrowForwardIcon fontSize="large" />
            </Button>
        </Box>
  );
};